import './borderedimageblocks/borderedimageblocks';
import './button/button';
import './floatingblock/floatingblock';
import './fullvideo/fullvideo';
import './photoalbum/photoalbum';
import './photoslider/photoslider';
import './rotaryprojects/rotaryprojects';
import './socialwall/socialwall';
import './textimage/textimage';
import './twocolumnstext/twocolumnstext';

import './old/contentbar/contentbar';
import './old/sliderimages/sliderimages';
import './old/threecolumns/threecolumns';

/*
  magazine
*/
import '@mod-rotarynl/widgets/largeimageandtext/largeimageandtext';
import '@mod-rotarynl/widgets/counter/counter';
import '@mod-rotarynl/widgets/foldableitems/foldableitems';
import '@mod-rotarynl/widgets/linkeditemsonthispage/linkeditemsonthispage';
import '@mod-rotarynl/widgets/motionblock/motionblock';
import '@mod-rotarynl/widgets/twocolumns/twocolumns';
import '@mod-rotarynl/widgets/imagewithoverlays/imagewithoverlays';
import '@mod-rotarynl/widgets/baseblock/baseblock';
import '@mod-rotarynl/widgets/stickybackgroundimageblock/stickybackgroundimageblock';
import '@mod-rotarynl/widgets/customalignedblock/customalignedblock';

/*
  new home
*/
import '@mod-rotarynl/widgets/personsspotlight/personsspotlight';
import '@mod-rotarynl/widgets/videoblock/videoblock';
import './slider/slider';
import '@mod-rotarynl/widgets/dividingline/dividingline';
