import * as whintegration from "@mod-system/js/wh/integration";
import * as rpc from './clubfinder2.rpc.json';
import $ from 'jquery';

import * as dompack from 'dompack';
import ClubFinderMap from '../../components/clubfindermap/clubfindermap';

import './clubfinder2.scss';

//var rotaryclubmarkericonurl = whintegration.config.imgroot + "markers/rotary-wheel-34x34.png";
var rotaryclubmarkericonurl = whintegration.config.imgroot + "markers/rotarypointer.48x48.png";
//var rotaractclubmarkericonurl = whintegration.config.imgroot + "markers/rotaract-wheel-34x34.png";
var rotaractclubmarkericonurl = whintegration.config.imgroot + "markers/rotaractpointer.30x30.png";

//console.log(rotaryclubmarkericonurl);

const debounce = (callback, waitTime) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            callback(...args);
        }, waitTime);
    };
}

export default class GEOMap {
  constructor(container, {
    resultsContainer = null,
    enableSpiderfier = true,
    center = { lat: 52.0946547, lng: 5.3456721 },
    zoom = 7,
    mapTypeControl = true,
    streetViewControl = true,
  } = {}) {

    if (resultsContainer) {
      this.$resultsContainer = $(resultsContainer);
    }

    this.currentClubs = []; // [{ ...data, marker: google.maps.Marker }]
    this.searching = false;

    // initialize the map
    this.map = new google.maps.Map(container, {
      center,
      zoom,
      maxZoom: 15, // Most roadmap imagery is available from zoom levels 0 to 18
      mapTypeControl,
      streetViewControl,
      scrollwheel: false,
      clickableIcons: false,
    });

    if (enableSpiderfier) {
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });
    }

    // initialize the Geocoder for text search
    this.geocoder = new google.maps.Geocoder();
  }

}

//dompack.register('.geomap', _node => new GEOMap(_node));


class ClubFinder2 {
  constructor(_node) {
    //this.map = new ClubFinderMap(node, { resultsContainer: dompack.qS('#clubfinder-results') });
    //dompack.qS('#clubfinder-searchform').addEventListener('submit', evt => { this.handleSearch(evt); });

    this.node = _node;
    this.$node = $(_node);

    this.$resultsContainer = $("#clubfinder-results");

    this.geomap = dompack.qS('.geomap');
    if (this.geomap) {
      this.map = new GEOMap(this.geomap);
    }

    this.findcluburl = this.$node.data("findcluburl");
    //console.log({ 'findcluburl': this.findcluburl });

    let classthis = this;
    this.currentitem = {};
    this.currentindex = -1;
    this.distance = 5000;
    this.currentClubs = [];
    this.infoWindow = null;
    
    this.inputfield = dompack.qS(_node, ".inputfield.autocomplete");
    this.autocompleteitems = dompack.qS(_node, ".autocomplete--items");

    this.inputfieldinput = dompack.qS(this.inputfield, "input");
    this.inputfieldinput.addEventListener('input', debounce(this.handleInput.bind(this), 500));
    this.inputfieldinput.addEventListener('keydown', this.handleKeyDown.bind(this));
    let inputvalue = this.inputfieldinput.value;
    if (inputvalue !== "") {
      let token = ""; // dompack.qS("#clubfinder-token").value;
      this.getRemoteAutocompleteData(inputvalue, token);
      dompack.focus(this.inputfieldinput);
      this.inputfieldinput.value = "";
      this.inputfieldinput.value = inputvalue;
    }
    //setTimeout(function() { classthis.closeAutocomplete(); }, 2000);
    setTimeout(function() { classthis.showAutocomplete(false); }, 500);

    this.selectDistance = dompack.qS(this.node, ".inputfield.select select");
    this.selectDistance.addEventListener('change', this.handleSelect.bind(this));
    
    this.inititem = this.$node.data("item");
    //console.log("inititem", this.inititem);

    if (this.inititem) {
      this.setDistance();
      this.currentitem = this.inititem;
      this.inputfieldinput.value = this.currentitem.displayname;
      this.refreshClubs();
    }
  }

  setDistance()
  {
    this.distance = parseInt(this.selectDistance.value);
    //console.log("setDistance", this.distance);
  }

  handleInput(_event) {
    let inputvalue = _event.target.value;
    let n_token = dompack.qS("#clubfinder-token");
    // n_token.value
    //console.log("input", inputvalue);
    //getTestData(inputvalue, token);
    this.getRemoteAutocompleteData(inputvalue, "token");
  }

  handleSelect(_event) {
    //console.log("change select");
    this.distance = parseInt(_event.target.value);
    //console.log("distance", this.distance);
    if (this.findcluburl) {
    }
    else {
      this.refreshClubs();
    }
  }

  async getRemoteAutocompleteData(_searchvalue, _token)
  {
    let data = await rpc.getTestData(_searchvalue, _token);
    //console.log("data", data);
    this.autocompleteitems.innerHTML = data.html;
    this.currentindex = -1;
    this.showAutocomplete(true);
    let classthis = this;
    $(this.autocompleteitems).off("click", "li");
    $(this.autocompleteitems).on("click", "li" , function(_e) {
      classthis.itemClick(_e);
    });
  }

  async getClubsWithinDistance(_reflatlng, _distance)
  {
    const response = await rpc.getClubsWithinDistance(_reflatlng, _distance);
    //console.log("clubswithindistance", response);
    this.setMarkersByClubs(response.clubs);
  }

  setMarkersByClubs(clubs = []) {
    if (this.refmarker) {
      this.refmarker.setMap(null);
    }

    // remove currently shown markers on map
    for (const club of this.currentClubs) {
      club.marker.setMap(null);
    }
    // reset vars
    this.currentClubs = [];
    // reset results
    this.clearResults();

    const nrofitems = clubs.length;
    //if(nrofitems>0){
    //  $(this.map).gmap('set','bounds',null);
    //  $(this.map).gmap('clear','markers');
    //}

    let bounds = new google.maps.LatLngBounds();

    let refloc = new google.maps.LatLng(this.currentitem.lat, this.currentitem.lng);
    bounds.extend(refloc);
    this.refmarker = new google.maps.Marker({
      position: new google.maps.LatLng(this.currentitem.lat, this.currentitem.lng),
      map: this.map.map,
      // title: club.name,
      clickable: true,
    });

    // add click event to the marker using spiderfier
    google.maps.event.addListener(this.refmarker, 'spider_click', () => this.showRefMarkerInfoWindow());

    // add marker to the map
    this.map.oms.addMarker(this.refmarker);

    for (const club of clubs) {
      // add location to bounds for auto fit/zoom/pan
      let loc = new google.maps.LatLng(club.lat, club.lng);
      bounds.extend(loc);
    }
    //console.log("map", this.map);
    this.map.map.fitBounds(bounds);
    this.map.map.panToBounds(bounds);

    // if no clubs found, we're done (remove any earlier search results)
    if (clubs.length === 0) {
      return;
    }


    let index = 0;
    for (let club of clubs) {
      // create a marker
      club.marker = new google.maps.Marker({
        position: new google.maps.LatLng(club.lat, club.lng),
        map: this.map.map,
        // title: club.name,
        clickable: true,
        icon: club.isrotaract ? rotaractclubmarkericonurl : rotaryclubmarkericonurl
      });

      // add click event to the marker using spiderfier
      google.maps.event.addListener(club.marker, 'spider_click', () => this.showClubInfoWindow(club));

      // add marker to the map
      this.map.oms.addMarker(club.marker);

      club.resultindex = index;

      // and add to (cached/current) markers
      this.currentClubs.push(club);
      index++;
    }

    this.showResultsForCurrentClubs();
  }

  showRefMarkerInfoWindow() {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    this.infoWindow = new google.maps.InfoWindow({
      content: 'Locatie waarop is gezocht',
      maxWidth: 500,
    });

    this.infoWindow.open(this.map.map, this.refmarker);
  }

  showClubInfoWindow(club) {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    let content = '<p class="club_type ' + ( club.isrotaract ? "club_type--rotaract" : "club_type--rotary" ) + '">' + ( club.isrotaract ? "Rotaract" : "Rotary" ) + ' Club</p>';
    content += `<p class="club_name">${club.wrd_orgname}</p>`;
    const info_node = dompack.create("div", { className: "infowindow", innerHTML: content });
    const more_link = dompack.create("a", { className: "more_link", textContent: "Naar meer info" });
    more_link.addEventListener('click', _event => {
      _event.preventDefault();
      //console.log("club", club);
      this.scrollToResult(club.resultindex);
    });
    info_node.appendChild(more_link);

    this.infoWindow = new google.maps.InfoWindow({
/*
      content: `<a class="c-infowindow" href="${club.website}" target="_blank">
                  RC ${club.wrd_orgname}<br />
                  <br />
                  Meeting: ${club.fmt_meeting_day} ${club.fmt_meeting_time} uur
                </a><a href="#" class=".club-click">Naar clubinfo</a>`,
*/
      content: info_node,
      maxWidth: 500,
    });

    this.infoWindow.open(this.map.map, club.marker);
  }

  clearResults() {
    if (this.$resultsContainer) {
      this.$resultsContainer.empty();
    }
  }

  showResultsForCurrentClubs() {
    if (!this.$resultsContainer) {
      return;
    }

    for (const club of this.currentClubs) {
      // setup HTML for search result block
      const $block = $(club.html);

      // add click event
      $block.find('.resultblock__showonmap').click(evt => {
        evt.preventDefault();
        this.scrollToMap();
        this.showClubInfoWindow(club);
      });

      this.$resultsContainer.append($block);
    }
  }

  scrollToResult(_index) {
    const $resultitem = $($("#clubfinder-results .c-result")[_index]);
    let addmargin = $('header .topbar__menubuttoncontainer').is(':visible') ? 61 : 0;
    $('html,body').animate({
      scrollTop: $resultitem.offset().top - addmargin,
    }, 500);
    setTimeout(function() {
      $resultitem.addClass("highlight");
      setTimeout(function() {
        $resultitem.removeClass("highlight");
      }, 4000);
    }, 250);
/*
*/
  }

  scrollToMap() {
    let addmargin = $('header .topbar__menubuttoncontainer').is(':visible') ? 61 : 0;
    $('html,body').animate({
      scrollTop: $(this.geomap).offset().top - addmargin,
    }, 500);
  }

  handleKeyDown(_event) {
    console.log("keydown, currentindex: ", this.currentindex, "keycode:", _event.keyCode);
    let $autocompletelis = this.$node.find('.autocomplete--items li');
    if (_event.keyCode == 40) { // DOWN
      _event.preventDefault();
      this.currentindex++;
      this.addActive();
    }
    else if (_event.keyCode == 38) { // UP
      _event.preventDefault();
      this.currentindex--;
      this.addActive();
    }
    else if (_event.keyCode == 13) { // ENTER
      _event.preventDefault();
      if (this.currentindex>-1) {
        //console.log("enter", this.currentindex);
        $autocompletelis[this.currentindex].click();
      }
      else {
        console.lgo("enter, not currentindex");
      }
    }

  }

  logCurrentItem()
  {
    //console.log(this.currentitem);
  }

  addActive()
  {
    let $autocompletelis = this.$node.find('.autocomplete--items li');
    console.log("autocompletelis", $autocompletelis);
    $autocompletelis.removeClass("active");
    if (this.currentindex >= $autocompletelis.length) {
      this.currentindex = 0;
    }
    if (this.currentindex<0) {
      this.currentindex = $autocompletelis.length -1;
    }
    console.log("currentindex", this.currentindex);
    $($autocompletelis[this.currentindex]).addClass("active");
  }

  showAutocomplete(_show)
  {
    if (_show) {
//console.log("removeClass nodisplay");
      this.autocompleteitems.classList.remove("nodisplay");
    }
    else {
//console.log("addClass nodisplay");
      this.autocompleteitems.classList.add("nodisplay");
    }

  }

  closeAutocomplete()
  {
    //console.log("closeAutocomplete");
    this.autocompleteitems.innerHTML = "";
    this.currentindex = -1;
  }

  refreshClubs() {
    if (this.currentitem) {
      const reflatlng = { 'lat': this.currentitem.lat, 'lng': this.currentitem.lng };
      //console.log({ 'reflatlng': reflatlng, 'distance': this.distance });
      this.getClubsWithinDistance(reflatlng, this.distance);
    }
  }

  itemClick(_e)
  {
    //console.log("itemClick");
    //console.log("target", _e.target);
    let $autocompletelis = this.$node.find('.autocomplete--items li');
    //let dataitem = $($autocompletelis[this.currentindex]).data("item");
    //let dataitem = JSON.parse($(_e.target).data("item"));
    let dataitem = $(_e.target).data("item");
    this.currentitem = dataitem;
    this.inputfieldinput.value = dataitem.displayname;
    //console.log("dataitem", dataitem);
    //console.log("currentitem", this.currentitem);
    this.closeAutocomplete();

    let inputvalue = this.inputfieldinput.value;
    dompack.focus(this.inputfieldinput);
    this.inputfieldinput.value = "";
    this.inputfieldinput.value = inputvalue;
    this.currentindex = -1;

    if (this.findcluburl) {
      const redirecturl = this.findcluburl + "?ed=" + this.currentitem.ed + "&d=" + this.distance;
      //console.log("redirect to " + redirecturl);
      window.location.href = redirecturl; // simulate click
      //window.location.replace(redirecturl) // simulate http redirect
    }
    else {
      //console.log("refresh clubs");
      this.refreshClubs();
    }
  }

  //async handleSearch(event) {
  //  dompack.stop(event);
  //  await this.map.search(dompack.qS('#clubfinder-searchinput').value);
  //}
}

dompack.register('#clubfinder2', node => new ClubFinder2(node));

dompack.register('.widget--personsspotlight .widget--personsspotlight__option', node => new ClubFinder2(node));
